export enum ActionType {
    TransactionReceived = 'TransactionReceived',
    TransactionSent = 'TransactionSent',
    CumulativeTransactionSum = 'CumulativeTransactionSum',
    CumulativeTransactionCount = 'CumulativeTransactionCount',
    CustomPropertyTransaction = 'CustomPropertyTransaction',
    CustomPropertyTransactionParty = 'CustomPropertyTransactionParty',
    CustomPropertyCustomer = 'CustomPropertyCustomer',
    TransactionCurrency = 'TransactionCurrency',
    TransactionAmount = 'TransactionAmount',
    AddLabel = 'AddLabel',
    Period = 'Period',
    CustomPropertyKyc = 'CustomPropertyKyc',
}

export enum ValueType {
    ByValue = 'ByValue',
    ByCustomerKyc = 'ByCustomerKyc',
    ByCustomerProperty = 'ByCustomerProperty',
}
