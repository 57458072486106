import { bindable, bindingMode } from 'aurelia-framework';

export class AddRule {
    @bindable text: string;
    @bindable sets: RuleSet[] = [];
    menu: HTMLElement;
    show: boolean = false;

    showMenu($event: Event) {
        // console.log('showMenu', this.sets);

        if (this.show) {
            return;
        }

        this.show = true;
        document.addEventListener('click', this.hideMenu);
    }

    hideMenu = () => {
        this.show = false;
        document.removeEventListener('click', this.hideMenu);
        this.menu.scrollTop = 0;
    };
}

export class RuleSet {
    name: string | null;
    icon: string | null;
    rules: Rule[] = [];

    constructor(name: string | null, icon: string | null, rules: Rule[]) {
        this.name = name;
        this.icon = icon;
        this.rules = rules;
    }
}

export class Rule {
    name: string;
    action: () => void;

    constructor(name: string, action: () => void) {
        this.name = name;
        this.action = action;
    }
}
