import { bindable, bindingMode } from 'aurelia-framework';
import './icon-dropdown.scss';
import { Item } from './show-rule';
import 'flag-icons/css/flag-icons.min.css';

export class IconDropdown {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable placeholder: string;
    @bindable selection: Item[] = [];
    name: string;
    icon: string;
    show: boolean = false;

    selectionChanged(value, old) {
        if (value === undefined) {
            return;
        }

        let lookup = this.selection.filter((f) => f.id === this.value);

        if (lookup.length !== 0) {
            this.name = lookup[0].name;
            this.icon = lookup[0].icon;
        } else {
            this.name = this.value;
        }
    }

    remove($event: Event) {
        this.value = null;
        this.hide();
        $event.stopPropagation();
    }

    hide = () => {
        this.show = false;
        document.removeEventListener('click', this.hide);
    };

    edit($event) {
        if (this.show) {
            return;
        }

        $event.stopPropagation();
        this.show = true;
        document.addEventListener('click', this.hide);
        return false;
    }

    select(item: Item) {
        this.hide();
        this.value = item.id;
        this.name = item.name;
        this.icon = item.icon;
    }
}
