import { bindable, bindingMode } from 'aurelia-framework';
import './input-combobox.scss';
import { Item } from './show-rule';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';

@autoinject
export class InputCombobox {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable placeholder: string;
    @bindable endpoint: string;
    editing: boolean = false;
    input: HTMLInputElement;
    show: boolean = false;
    @bindable selection: Item[] = [];
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    async edit($event: Event) {
        if (this.show) {
            return true;
        }

        if (this.editing) {
            return true;
        }

        this.editing = true;
        await new Promise((f) => setTimeout(f, 0));
        this.input.select();
        this.input.focus();
        this.scan(this.value);
        return true;
    }

    async scan(text: string) {
        if (text === null) {
            text = '';
        }

        let selection = [];
        const response = await this.httpClient.get(`${this.endpoint}?prefix=${text}`);
        const data = (await response.json()).list;

        for (var i = 0; i < data.length; ++i) {
            const text = data[i].name;

            selection.push(new Item(text, text));
        }

        this.selection = selection;

        if (selection.length !== 0) {
            this.showMenu();
        } else {
            this.hide();
        }
    }

    showMenu() {
        if (this.show) {
            return;
        }

        this.show = true;
        document.addEventListener('click', this.hide);
    }

    blur() {
        this.stopEditing();
    }

    stopEditing = () => {
        if (!this.editing) {
            return;
        }

        this.editing = false;
        this.value = this.input.value;
    };

    remove($event: Event) {
        $event.stopPropagation();
        this.value = '';
        this.hide();
        this.editing = false;
    }

    keydown($event: KeyboardEvent) {
        if ($event.key == 'Enter' || $event.key == 'Escape') {
            this.stopEditing();
            this.hide();
            return false;
        }

        return true;
    }

    keyup() {
        this.scan(this.input.value);
        return true;
    }

    hide = () => {
        this.show = false;
        document.removeEventListener('click', this.hide);
    };

    select(item: Item, $event: Event) {
        this.value = item.id;
        this.editing = false;
        this.hide();
        $event.stopPropagation();
        return false;
    }
}
