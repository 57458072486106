import { bindable, bindingMode } from 'aurelia-framework';
import { Item } from './show-rule';
import { containerless, customElement } from 'aurelia-framework';

@containerless()
export class InputBy {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) valueType: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable byList: Item[] = [];

    constructor() {
        this.byList.push(new Item('ByValue', 'By Value'));
        this.byList.push(new Item('ByCustomerKyc', 'By Kyc'));
        this.byList.push(new Item('ByCustomerProperty', 'By Customer'));
    }

    valueTypeChanged(newValue, oldValue) {
        this.value = '';
    }
}
