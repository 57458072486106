import { PLATFORM, bindable, bindingMode } from 'aurelia-framework';
import { ActionType } from '../types';

let ruleTypes: Record<ActionType, string> = {
    [ActionType.Period]: PLATFORM.moduleName('./compose/time-period.html'),
    [ActionType.AddLabel]: PLATFORM.moduleName('./compose/add-label.html'),
    [ActionType.CumulativeTransactionCount]: PLATFORM.moduleName('./compose/cumulative-count.html'),
    [ActionType.CumulativeTransactionSum]: PLATFORM.moduleName('./compose/cumulative-sum.html'),
    [ActionType.TransactionAmount]: PLATFORM.moduleName('./compose/transaction-amount.html'),
    [ActionType.CustomPropertyTransaction]: PLATFORM.moduleName('./compose/custom-property-transaction.html'),
    [ActionType.CustomPropertyTransactionParty]: PLATFORM.moduleName(
        './compose/custom-property-transaction-party.html',
    ),
    [ActionType.CustomPropertyCustomer]: PLATFORM.moduleName('./compose/custom-property-customer.html'),
    [ActionType.TransactionReceived]: PLATFORM.moduleName('./compose/transaction-received.html'),
    [ActionType.TransactionSent]: PLATFORM.moduleName('./compose/transaction-sent.html'),
    [ActionType.TransactionCurrency]: PLATFORM.moduleName('./compose/transaction-currency.html'),
    [ActionType.CustomPropertyKyc]: PLATFORM.moduleName('./compose/custom-property-kyc.html'),
};

export class ShowRule {
    @bindable action: any;
    @bindable remove: any;
    @bindable index: number;
    @bindable operator: string = null;
    @bindable lhs: HTMLSpanElement = null;
    @bindable propertiesCustomer: Item[] = [];
    @bindable propertiesTransaction: Item[] = [];
    @bindable propertiesTransactionParty: Item[] = [];
    operators: Item[] = [];
    currencies: Item[] = [];
    simple: Item[] = [];
    periods: Item[] = [];
    from: string;
    to: string;
    period: string;
    ruletype: string;

    constructor() {
        this.currencies = [
            new Item('EURO', 'Euro', 'fi fi-eu'),
            new Item('SEK', 'SEK', 'fi fi-se'),
            new Item('USD', 'USD', 'fi fi-us'),
        ];

        this.operators = [
            new Item('eq', 'Equal To'),
            new Item('neq', 'Not Equal To'),
            new Item('lt', 'Less Than'),
            new Item('le', 'Less or Equal To'),
            new Item('ge', 'Greater or Equal To'),
            new Item('gt', 'Greater Than'),
        ];

        this.simple = [
            new Item('eq', 'Equals To'),
            new Item('neq', 'Not Equal To'),
            new Item('lt', 'Less Than'),
            new Item('le', 'Less or Equal To'),
            new Item('ge', 'Greater or Equal To'),
            new Item('gt', 'Greater Than'),
            new Item('contains', 'Contains'),
            new Item('startswith', 'Starts With'),
            new Item('endswith', 'Ends With'),
        ];

        this.periods = [new Item('day', 'Days'), new Item('week', 'Weeks'), new Item('month', 'Months')];
    }

    attached() {
        this.ruletype = ruleTypes[this.action.type];
    }

    lhsKeydown($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            return false;
        }

        return true;
    }

    lhsClick() {
        this.selectAll(this.lhs);
    }

    selectAll(element: HTMLElement) {
        var selection = window.getSelection();
        var range = document.createRange();

        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    edit() {
        // this.lhsKeydown.edi
        // console.log('edit!');
        // this.lhs.focus();
    }
}

export class Item {
    id: string;
    name: string;
    icon: string;

    constructor(id: string, name: string, icon: string = null) {
        this.id = id;
        this.name = name;
        this.icon = icon;
    }
}
