import { bindable, bindingMode } from 'aurelia-framework';
import './input-text.scss';

export class InputText {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable placeholder: string;
    editing: boolean = false;
    input: HTMLInputElement;

    async edit($event: Event) {
        if (this.editing) {
            return false;
        }

        this.editing = true;
        this.input.value = this.value || '';
        await new Promise((f) => setTimeout(f, 0));
        this.input.select();
        this.input.focus();
        return true;
    }

    blur($event) {
        this.stopEditing();
    }

    stopEditing = () => {
        this.editing = false;
        this.value = this.input.value;
    };

    remove($event: Event) {
        $event.stopPropagation();
        this.value = null;
        this.editing = false;
    }

    keydown($event: KeyboardEvent) {
        if ($event.key == 'Enter' || $event.key == 'Escape') {
            this.stopEditing();
            return false;
        }

        return true;
    }
}
