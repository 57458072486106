import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import Chart from 'chart.js/auto';
import { ChartConfiguration, ChartData } from 'chart.js';
//TODO: https://github.com/chartjs/chartjs-plugin-datalabels is nicer in test charts
import annotationPlugin from 'chartjs-plugin-annotation';
import './graph.scss';
import { ReportDetails } from 'reports/details/report-details';

Chart.register(annotationPlugin);
Chart.defaults.color = '#B6BABD';
Chart.defaults.font.size = 14;

export class Model {
    history: Object = {};
    percentage: number = 0;
    percentageRounded: number = 0;

    public static Build(json: any) {
        let result = new Model();

        result.history = json.history;
        result.percentage = json.percentage;
        result.percentageRounded = Math.round(json.percentage);
        return result;
    }

    public static Empty() {
        let result = new Model();

        return result;
    }
}

export class HistoryItem {
    falsePositives: number;
    alerts: number;

    constructor(falsePositives: number, alerts: number) {
        this.falsePositives = falsePositives;
        this.alerts = alerts;
    }
}

@autoinject
export class Graph {
    @bindable model: Model;
    chart: Chart;
    canvas: HTMLCanvasElement;
    historyLabels: any = [];
    alerts: any[] = [];
    falsePositives: any[] = [];

    attached() {
        // console.log('performance');
        // console.dir(this.canvas);
        // console.log('graph', this.model);
        this.updateChart();
    }

    bindChart(model: Model) {
        this.historyLabels = Object.keys(model.history);
        let items: any = Object.values(model.history); //.map(x => x.alerts)
        this.alerts = items.map((x) => x.alerts);
        this.falsePositives = items.map((x) => x.falsePositives);

        // console.log('historyLabels', this.historyLabels);
        // console.log('alerts', this.alerts);
        // console.log('falsePositives', this.falsePositives);
    }

    updateChart() {
        let annotations = {};

        if (!this.canvas) {
            return;
        }

        // if (this.historyLabels == null || this.historyLabels.length == 0) {
        //     return;
        // }

        let hasFalsePositive = !this.falsePositives.every((v) => v == 0);

        let datasets = [
            {
                label: 'Alerts',
                data: [...this.alerts],
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'withdrawal',
                },
                borderColor: '#00DD9A',
                backgroundColor: '#00DD9A',
                order: 2,
            },
        ];

        if (hasFalsePositive) {
            console.table(this.falsePositives);
            datasets.push({
                label: 'False Positive',
                data: [...this.falsePositives],
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'withdrawal',
                },
                borderColor: '#FF7C7C',
                backgroundColor: '#FF7C7C',
                order: 2,
            });
        }

        if (this.chart === undefined) {
            this.chart = new Chart(this.canvas, {
                type: 'bar',
                data: {
                    labels: [...this.historyLabels],
                    datasets: datasets,
                },
                options: {
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        annotation: {
                            annotations: annotations,
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                drawOnChartArea: false,
                                tickLength: 0,
                            },
                        },
                        y: {
                            ticks: {
                                // forces step size to be 50 units
                                stepSize: 10,
                            },
                            suggestedMax: 10,

                            grid: {
                                tickLength: 0,
                                display: true,
                                borderDash: [5, 5],
                                drawTicks: true,
                                color: '#D4D4D4',
                                drawBorder: false,
                            },
                        },
                    },
                },
            });
        } else {
            this.chart.data.labels = [...this.historyLabels];
            this.chart.data.datasets[0].data = [...this.alerts];

            if (hasFalsePositive) {
                this.chart.data.datasets[1].data = [...this.falsePositives];
            }

            // this.chart.data.datasets.forEach(dataset => dataset.data = this.alerts);
            // this.chart.options.plugins.annotation.annotations = annotations;
            this.chart.update();
        }
    }

    modelChanged(value: Model, oldValue: Model) {
        // console.dir(value);
        // console.log("model updated");
        this.bindChart(value);
        this.updateChart();
    }
}
