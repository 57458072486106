import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import './details.scss';
import { Rule, RuleSet } from './components/add-rule';
import { Item } from './components/show-rule';
import { Router } from 'aurelia-router';
import { ErrorService } from 'error/error-service';
import { Model } from './graph/graph';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { route } from 'utils/route';
import { ActionType, ValueType } from './types';
import { inject, bindable, bindingMode, DOM } from 'aurelia-framework';
import { ToastsService } from 'toasts/toasts-service';
import { ValidationRules, ValidationControllerFactory, ValidationController } from 'aurelia-validation';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class RuleDetail {
    httpClient: HttpClient;
    router: Router;
    id: string;
    showCondition: boolean = false;
    given: RuleSet[] = [];
    when: RuleSet[] = [];
    then: RuleSet[] = [];
    triggers: Value[] = [];
    conditions: Value[] = [];
    actions: Value[] = [];
    propertiesCustomer: Item[] = [];
    propertiesTransaction: Item[] = [];
    propertiesTransactionParty: Item[] = [];
    rule: Rule2 = new Rule2();
    errorService: ErrorService;
    code: string = '{}';
    history: Model = Model.Empty();
    page: string = 'Editing';
    confirmService: ConfirmService;
    running: boolean = true;
    from: Date | null = null;
    @bindable fromDate: string | null;
    future: boolean = false;
    toastsService: ToastsService;
    controller: ValidationController;
    inputModel: InputModel = new InputModel();
    currentDate: boolean = false;
    buttonsDisabled: boolean = false;

    constructor(
        httpClient: HttpClient,
        router: Router,
        errorService: ErrorService,
        confirmService: ConfirmService,
        toastsService: ToastsService,
        controllerFactory: ValidationControllerFactory,
    ) {
        this.httpClient = httpClient;
        this.router = router;
        this.errorService = errorService;
        this.confirmService = confirmService;
        this.toastsService = toastsService;
        this.controller = controllerFactory.createForCurrentScope();
        this.given = [
            new RuleSet(null, null, [
                new Rule('Transaction is received', this.addTransactionReceived),
                new Rule('Transaction is sent', this.addTransactionSent),
            ]),
        ];
        this.when = [
            new RuleSet('Cumulative', '123', [
                new Rule('Count...', this.addCumulativeTransactionCount),
                new Rule('Sum...', this.addCumulativeTransactionSum),
            ]),
            new RuleSet('Time', 'clock', [new Rule('Period...', this.addPeriod)]),
            new RuleSet('Customer', 'person', [
                new Rule('Attribute...', this.addCustomPropertyCustomer),
                new Rule('KYC Attribute...', this.addCustomPropertyKyc),
            ]),
            new RuleSet('Transaction', 'cash-coin', [
                new Rule('Amount...', this.addTransactionAmount),
                new Rule('Currency...', this.addTransactionCurrency),
                new Rule('Attribute...', this.addCustomPropertyTransaction),
            ]),
            new RuleSet('Transaction Party', 'person', [
                new Rule('Attribute...', this.addCustomPropertyTransactionParty),
            ]),
        ];
        this.then = [new RuleSet(null, null, [new Rule('Add Label', this.addLabel)])];
        this.loop();
    }

    async fromDateChanged(value) {
        let format: string | null = null;
        let date: Date | null = null;

        if (value !== '') {
            date = new Date(value);
            format = date.toDateString();
            this.future = date > new Date();
            this.currentDate = date == new Date();
        } else {
            this.future = false;
        }

        this.from = date;
        this.history = Model.Empty();

        const msg = {
            from: date,
        };

        await this.httpClient.put(`rules/${this.id}/from`, json(msg));
    }

    loop = async () => {
        while (this.running) {
            await new Promise((f) => setTimeout(f, 2_000));

            if (this.rule.state === 'Editing' || this.id === undefined) {
                continue;
            }

            let response = await this.httpClient.get(`rules/${this.id}`);
            let data = await response.json();

            this.history = Model.Build(data);
            this.rule.alerts = data.alerts;
            this.rule.processed = data.processed;
        }
    };

    detached() {
        this.running = false;
    }

    async activate(params) {
        params = cleanParamsObject(params);
        this.id = params.id;

        if (this.id !== undefined) {
            const response = await this.httpClient.get(`rules/${this.id}`);
            const data = await response.json();

            this.inputModel = new InputModel(data.name, data.description);
            this.triggers = data.triggers;
            this.conditions = data.conditions;
            this.actions = data.actions;
            this.rule = data;
            this.page = data.state;
            this.history = Model.Build(data);
            this.code = JSON.stringify(data, null, 2);

            if (data.startDate !== null) {
                this.from = new Date(data.startDate);
                this.fromDate = this.from.toLocaleDateString();
            } else {
                this.from = null;
                this.fromDate = null;
            }
        }

        ValidationRules.ensure((m: InputModel) => m.name)
            .required()
            .ensure((m) => m.description)
            .required()
            .on(this.inputModel);
    }

    async attached() {
        try {
            const fetch = async (url: string): Promise<Item[]> => {
                const response = await this.httpClient.get(url);

                if (!response.ok) {
                    throw new Error(`Could not fetch transactions from endpoint: ${url}`);
                }

                const data: { list: { name: string }[]; total: number } = await response.json();
                const transformed = data.list.map((x) => new Item(x.name, x.name));

                return transformed;
            };

            this.propertiesTransaction = await fetch('transactions/properties');
            this.propertiesTransactionParty = await fetch('transactions/party/properties');
            this.propertiesCustomer = await fetch('customer/properties');
        } catch (error) {
            console.error(error);
            this.propertiesTransaction = [];
            this.propertiesTransactionParty = [];
            this.propertiesCustomer = [];
        }
    }

    async saveInternal() {
        let model = {
            name: this.inputModel.name,
            description: this.inputModel.description,
            triggers: this.triggers,
            conditions: this.conditions,
            actions: this.actions,
        };

        if (this.id === undefined) {
            const response = await this.httpClient.post(`rules`, json(model));
            const id = (await response.json()).ruleId;

            this.id = id;
            this.router.navigate(`/rules/${id}`, {
                replace: true,
                trigger: false,
            });
        } else {
            await this.httpClient.put(`rules/${this.id}`, json(model));
        }
    }

    async save() {
        await this.controller.validate().then(async (value) => {
            if (value.valid) {
                try {
                    await this.saveInternal();
                    this.toastsService.success('Success!', 'Rule saved', 5000);
                } catch (error) {
                    const errorMessages = error.errors
                        ? Object.values(error.errors).map((errorArray) => errorArray[0])
                        : [];
                    this.errorService.showError('Save error', error.message + ' ' + errorMessages.join(', '));
                }
            } else {
                const message = value.results
                    .filter((x) => !x.valid)
                    .map((x) => x.message)
                    .join(' ');
                this.toastsService.error('Rule is not valid', message, 5000);
            }
        });
    }

    async preview() {
        try {
            this.buttonsDisabled = true;
            await this.saveInternal();
            await this.httpClient.put(`rules/${this.id}/preview`);
            this.rule.state = 'Preview';
            this.rule.running = true;
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this.buttonsDisabled = false;
        }
    }

    async review() {
        try {
            this.buttonsDisabled = true;
            await this.httpClient.put(`rules/${this.id}/review`);
            this.rule.state = 'Editing';
            this.rule.running = false;
            this.history = Model.Empty();
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this.buttonsDisabled = false;
        }
    }

    async deploy() {
        this.confirmService.confirm(
            'Deploy Rule?',
            'Are you sure you want to deploy the rule. This will create alerts.',
            async () => {
                try {
                    this.buttonsDisabled = true;
                    await this.httpClient.put(`rules/${this.id}/deploy`);
                    this.rule.state = 'Live';
                } catch (error) {
                    this.errorService.showError('Save error', error.message);
                } finally {
                    this.buttonsDisabled = false;
                }
            },
        );
    }

    async duplicate() {
        this.confirmService.confirm(
            'Clone rule?',
            `Are you sure you want to clone the rule: ${this.rule.name}.`,
            async () => {
                const response: any = await this.httpClient.post(`rules/${this.id}/clone`);
                const id = (await response.json()).ruleId;

                this.router.navigate(route`/rules/${id}`);
            },
        );
    }

    async resume() {
        try {
            this.buttonsDisabled = true;
            await this.httpClient.put(`rules/${this.id}/resume`);
            this.rule.state = 'Live';
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this.buttonsDisabled = false;
        }
    }

    async pause() {
        try {
            this.buttonsDisabled = true;
            await this.httpClient.put(`rules/${this.id}/pause`);
            this.rule.state = 'Paused';
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this.buttonsDisabled = false;
        }
    }

    async delete() {
        this.confirmService.confirm(
            'Delete Rule?',
            'Are you sure you want to delete the rule. This action cannot be undone.',
            async () => {
                try {
                    await this.httpClient.delete(`rules/${this.id}`);
                    this.router.navigate(`/rules`);
                } catch (error) {
                    this.errorService.showError('Save error', error.message);
                }
            },
        );
    }

    addTransactionReceived = () => {
        this.triggers.push(new Value(ActionType.TransactionReceived));
    };

    addTransactionSent = () => {
        this.triggers.push(new Value(ActionType.TransactionSent));
    };

    addCumulativeTransactionSum = () => {
        this.conditions.push(new Value(ActionType.CumulativeTransactionSum));
    };

    addTransactionAmount = () => {
        this.conditions.push(new Value(ActionType.TransactionAmount));
    };

    addCumulativeTransactionCount = () => {
        this.conditions.push(new Value(ActionType.CumulativeTransactionCount));
    };

    addCustomPropertyTransaction = () => {
        this.conditions.push(new Value(ActionType.CustomPropertyTransaction));
    };

    addCustomPropertyTransactionParty = () => {
        this.conditions.push(new Value(ActionType.CustomPropertyTransactionParty));
    };

    addCustomPropertyCustomer = () => {
        this.conditions.push(new Value(ActionType.CustomPropertyCustomer));
    };

    addCustomPropertyKyc = () => {
        this.conditions.push(new Value(ActionType.CustomPropertyKyc));
    };

    addTransactionCurrency = () => {
        this.conditions.push(new Value(ActionType.TransactionCurrency));
    };

    addPeriod = () => {
        this.conditions.push(new Value(ActionType.Period));
    };

    addLabel = () => {
        this.actions.push(new Value(ActionType.AddLabel));
    };

    removeTrigger = (index: number) => {
        this.triggers.splice(index, 1);
    };

    removeCondition = (index: number) => {
        this.conditions.splice(index, 1);
    };

    removeAction = (index: number) => {
        this.actions.splice(index, 1);
    };
}

class Value {
    type: ActionType;
    value: string | null;
    property: string | null;

    constructor(type: ActionType, value: string | null = null, property: string | null = null) {
        this.type = type;
        this.value = value;
        this.property = property;
    }
}

class Condition extends Value {
    type: ActionType;
    value: string | null;
    property: string | null;
    ruleType: ValueType;

    constructor(type: ActionType, value: string | null = null, property: string | null = null, ruleType: ValueType) {
        super(type, value, property);
        this.type = type;
        this.value = value;
        this.property = property;
        this.ruleType = ruleType;
    }
}

class Rule2 {
    state: string = 'Editing';
    running: boolean = false;
    alerts: number = 0;
    processed: number = 0;
    name: string;
}

class InputModel {
    name: string;
    description: string;

    constructor(name: string = '', description: string = '') {
        this.name = name;
        this.description = description;
    }
}
